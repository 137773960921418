import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";



class Index extends Component {


    pricing = {
        inr:{monthly:[0,1499,3599,7499],yearly:[0,14990,35990,74990]},
        usd:{monthly:[0,30,49,99],yearly:[0,300,490,990]}
    }

    features =[{
        'Features':[

            {'Users':['1','1','10','25','Custom']},
            {'Invoices':['1200/year','Unlimited','Unlimited','Unlimited','Unlimited']},
            {'Recurring Invoices':['No','Yes','','','Yes']},
            {'Estimates':['No','Yes','','','Yes']},
            {'Sales Orders':['No','Yes','','','Yes']},
            {'Delivery Challans':['No','Yes','','','Yes']},
            {'Credit Notes':[]},
            {'Bills':[]},
            {'Purchase Orders':['No','Yes','','','Yes']},
            {'Vendor Credits':[]},
            {'Manage Clients':[]},
            {'Stock Tracking':[]},
            {'Payment Reminders':['No','Yes','','','Yes']},
            {'Expenses':[]},
            {'Manual Journals':[]},
            {'Chart of Accounts':[]},
            {'Sub-Accounts':[]},
            {'Staff Role and Access':['No','Yes','','','Yes']},
            {'General Reports':[]},
            {'Consolidated Reports':['No','Yes','','','Yes']},
            {'Custom Fields':[]},
            {'Multi-currency Invoicing':['No','Yes','','','Yes']},
            {'Automatic Exchange Rates':['No','Yes','','','Yes']},
            {'Currency Adjustments':['No','Yes','','','Yes']},
            {'Client Approval':['No','Yes','','','Yes']},
            {'Task Management':['No','Yes','','','Yes']},
            {'Jobsheet':['No','Yes','','','Yes']},
            {'Service Center Management':['No','Yes','','','Yes']},
            {'Inventory Management':[]},
            {'Android/IOS App':[]},
            {'Desktop POS App':[]},
            {'Mobile POS App':[]},
            {'Cloud Storage':['Upto 1GB','Upto 10GB','Upto 25GB','Upto 10GB','Custom']},
            {'Notification Emails':[]},
            {'Notification SMS':[]},
            {'Notification Whatsapp':[]},
        ],
        'Advance Inventory Control':[
            {'Location Management':[]},
            {'Department Management':[]},
            {'Serial Number Tracking':[]},
            {'Print Barcode / QR Code':[]}
        ],
        'Advance Analytics':[
            {'View real-time reports and dashboards':[]},
            {'Analyze and track key financial metrics':[]},
            {'Export and print reports as excel or PDF':[]}
        ],
        'GST':[
            {'Track GST':[]},
            {'GST Returns':[]},
            {'e Invoice': ['No', 'No', 'No','No','Yes']},
            {'e Way Bill': ['No', 'No', 'No','No','Yes']}
        ],
        'Customer Support':[
            {'Email':['Standard','Priority','Premium','Priority','Priority']},
            {'Chat/Phone': ['', '', '','24/7','24/7']}
        ],
    }]

    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        const currency = queryParams.get('currency');
        this.state={
            free:'show',basic:'',premium:'',pro:'',enterprice:'',currency:currency || 'usd',pricetype:'monthly'
        }
    }

    componentDidMount() {

    }

    toCurrency = (value) => {
        const {currency} = this.state;
        return new Intl.NumberFormat('en-US',
            { style: 'currency', currency: currency.toUpperCase(),maximumFractionDigits:0 }
        ).format(value);
    }

    render() {

        const {free,basic,premium,pro,enterprice,currency,pricetype} = this.state;
        let price = this.pricing[currency][pricetype];
        let per = 'per ' + (pricetype === 'monthly'?'month / per location':'year / per location')

        return(
            <Fragment>

                <div className="text-center mb-5">
                    <div className={' m-auto'}>
                        <a  className={`custom-btn py-2 px-4 custom-btn--style-${pricetype==='monthly'?4:2}`} onClick={()=>this.setState({pricetype:'monthly'})}>Monthly</a>
                        <a  className={`custom-btn ml-3 py-2 px-4 custom-btn--style-${pricetype==='yearly'?4:2}`} onClick={()=>this.setState({pricetype:'yearly'})} >Yearly</a>
                    </div>
                </div>


                <div className="pricing-table pricing-table--s1">
                    <div className="">
                        <div className="justify-content-sm-center row-reverse justify-content-md-start">


                            <div className={'d-block d-lg-none'}>
                                <nav className="nav">
                                    <a className={`nav-link ${free}`} href="#" onClick={()=> this.setState({free:'show',basic:'',premium:'',pro:'',enterprice:''})}>Free</a>
                                    <a className={`nav-link ${basic}`} href="#" onClick={()=> this.setState({free:'',basic:'show',premium:'',pro:'',enterprice:''})}>Professional</a>
                                    <a className={`nav-link premium d-none-mobile ${premium}`} href="#" onClick={()=> this.setState({free:'',basic:'',premium:'show',pro:'',enterprice:''})}>Premium</a>
                                    <a className={`nav-link pro d-none-mobile ${pro}`} href="#" onClick={()=> this.setState({free:'',basic:'',premium:'',pro:'show',enterprice:''})}>Pro</a>
                                    <a className={`nav-link ${enterprice}`} href="#" onClick={()=> this.setState({free:'',basic:'',premium:'',pro:'',enterprice:'show'})}>Enterprise</a>
                                </nav>
                            </div>


                            <div className={'table-responsive'}>
                                <table className={'fixed-layout'}>
                                    <tbody>
                                    <tr className={'plan'}>
                                        <td className={'d-none-mobile'}> <div className={'heading'}>     </div></td>
                                        <td className={ `free ${free}`}> <h3 className={'__title h3'}> <span>Free</span> </h3> </td>
                                        <td className={`basic ${basic}`}><h3  className={'__title h3'}> <span>Professional</span> </h3></td>
                                        <td className={`premium ${premium}`}><h3  className={'__title h3'}> <span>Premium</span> </h3></td>
                                        <td className={`pro ${pro}`}><h3  className={'__title h3'}> <span>Pro</span> </h3></td>
                                        <td className={`enterprice ${enterprice}`}><h3  className={'__title h3'}> <span>Enterprise</span> </h3></td>
                                    </tr>
                                    {/*<tr className={'plan'}>
                                        <td className={'d-none-mobile'}></td>
                                        <td className={  `free ${free}`}>Everything you need to handle day-to-day service.</td>
                                        <td className={`basic ${basic}`}>Streamline operations and boost revenue as your needs grow more complex.</td>
                                        <td className={`premium ${premium}`}>Get a custom plan that scales as you expand your footprint and your brand.</td>
                                        <td className={`pro ${pro}`}>Get a custom plan that scales as you expand your footprint and your brand.</td>
                                        <td className={`enterprice ${enterprice}`}>Get a custom plan that scales as you expand your footprint and your brand.</td>
                                    </tr>*/}
                                    <tr className={'plan'}>
                                        <td className={'d-none-mobile'}></td>
                                        <td  className={`free ${free}`}><span className={'highlight'}>{this.toCurrency(price[0])} </span> <div> <small> One location </small></div> </td>
                                        <td className={`basic ${basic}`}> <span className={'highlight'}> {this.toCurrency(price[1])} </span> <div> <small> {per} </small></div> </td>
                                        <td className={`premium ${premium}`}><span className={'highlight'}>{this.toCurrency(price[2])}</span> <div> <small> {per} </small></div></td>
                                        <td className={`pro ${pro}`}><span className={'highlight'}>{this.toCurrency(price[3])}</span> <div> <small> {per} </small></div></td>
                                        <td className={`enterprice ${enterprice}`}><span className={'highlight'}> Custom Pricing </span> <div>  </div></td>
                                    </tr>
                                    <tr className={'plan'}>
                                        <td className={'d-none-mobile'}></td>
                                        <td  className={`free ${free}`}></td>
                                        <td className={`basic ${basic}`}></td>
                                        <td className={`premium ${premium}`}></td>
                                        <td className={`pro ${pro}`}></td>
                                        <td className={`enterprice ${enterprice}`}></td>
                                    </tr>
                                    <tr className={'plan'}>
                                        <td className={'d-none-mobile'}></td>
                                        <td className={`free ${free}`}>
                                            <a  className="custom-btn custom-btn--big custom-btn--style-4 mb-3  nav-link"
                                                  onClick={()=>window.location.href='https://account.dhru.com/checkout?pid=3d44bcb5-afbc-4153-af6a-aa3457ebe119'} style={{minWidth:150}}>Get Started</a>
                                        </td>
                                        <td className={`basic ${basic}`}>
                                            <a  className="custom-btn custom-btn--big custom-btn--style-4 mb-3 nav-link"
                                                onClick={()=>window.location.href='https://account.dhru.com/checkout?aids=62d144cf-b02c-4996-8260-f2ffb6798666&pid=3d44bcb5-afbc-4153-af6a-aa3457ebe119&terms='+pricetype} style={{minWidth:150}}>Buy Now </a>
                                        </td>
                                        <td className={`premium ${premium}`}>
                                            <a  className="custom-btn custom-btn--big custom-btn--style-4 mb-3 nav-link"
                                                onClick={()=>this.props.checkout()} style={{minWidth:150}}>Buy Now</a>
                                        </td>
                                        <td className={`pro ${pro}`}>
                                            <a  className="custom-btn custom-btn--big custom-btn--style-4 mb-3 nav-link"
                                                onClick={()=>this.props.checkout()} style={{minWidth:150}}>Buy Now</a>
                                        </td>
                                        <td className={`enterprice ${enterprice}`}>
                                            <a  className="custom-btn custom-btn--big custom-btn--style-4 mb-3 nav-link"
                                                href="/contactus" style={{minWidth:150}}>Contact Us</a>
                                        </td>
                                    </tr>
                                    </tbody>

                                </table>

                                <table className={'fixed-layout border-bottom'}>
                                    <tbody>
                                    {
                                        this.features.map((features,key)=>{
                                            return Object.keys(features).map((key)=>{
                                                return <Fragment key={key}>
                                                    <tr className={'border-top'}>
                                                        <td></td>
                                                        <td className={`free ${free}`}></td>
                                                        <td className={`basic ${basic}`}></td>
                                                        <td className={`premium ${premium}`}> </td>
                                                        <td className={`pro ${pro}`}></td>
                                                        <td className={`enterprice ${enterprice}`}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className={'heading'}>{key}</td>
                                                        <td className={`free ${free}`}></td>
                                                        <td className={`basic ${basic}`}></td>
                                                        <td className={`premium ${premium}`}></td>
                                                        <td className={`pro ${pro}`}></td>
                                                        <td className={`enterprice ${enterprice}`}></td>
                                                    </tr>
                                                    {
                                                        features[key].map((item,key2)=>{
                                                            let label = Object.keys(item)[0];
                                                            return (
                                                                <tr key={key+key2}>
                                                                    <td className={'head'}>{label}</td>
                                                                    {!Boolean(item[label].length) ? <>
                                                                        <td className={`free ${free}`}><div> <i className="ico-checked fontello-ok"></i></div></td>
                                                                        <td className={`basic ${basic}`}><div> <i className="ico-checked fontello-ok"></i></div></td>
                                                                        <td className={`premium ${premium}`}><div> <i className="ico-checked fontello-ok"></i></div></td>
                                                                        <td className={`pro ${pro}`}><div> <i className="ico-checked fontello-ok"></i></div></td>
                                                                        <td className={`enterprice ${enterprice}`}><div> <i className="ico-checked fontello-ok"></i></div></td>
                                                                    </> : <>
                                                                        <td className={`free ${free}`}><div>{(item[label][0] === 'Yes'? <i className="ico-checked fontello-ok"></i> : item[label][0] === 'No' ? <i className="ico-checked fontello-cancel"></i> : item[label][0])}</div></td>
                                                                        <td className={`basic ${basic}`}><div>{(item[label][1] === 'Yes'? <i className="ico-checked fontello-ok"></i> : item[label][1] === 'No' ? <i className="ico-checked fontello-cancel"></i> : item[label][1])}</div></td>
                                                                        <td className={`premium ${premium}`}><div>{(item[label][2] === 'Yes'? <i className="ico-checked fontello-ok"></i> : item[label][2] === 'No' ? <i className="ico-checked fontello-cancel"></i> : item[label][2])}</div></td>
                                                                        <td className={`pro ${pro}`}><div>{(item[label][3] === 'Yes'? <i className="ico-checked fontello-ok"></i> : item[label][3] === 'No' ? <i className="ico-checked fontello-cancel"></i> : item[label][3])}</div></td>
                                                                        <td className={`enterprice ${enterprice}`}><div>{(item[label][4] === 'Yes'? <i className="ico-checked fontello-ok"></i> : item[label][4] === 'No' ? <i className="ico-checked fontello-cancel"></i> : item[label][4])}</div></td>
                                                                    </>}

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            })
                                        })
                                    }

                                    <tr className={'border-bottom'}>
                                        <td></td>
                                        <td className={`free ${free}`}></td>
                                        <td className={`basic ${basic}`}></td>
                                        <td className={`premium ${premium}`}> </td>
                                        <td className={`pro ${pro}`}></td>
                                        <td className={`enterprice ${enterprice}`}></td>
                                    </tr>
                                    </tbody>

                                </table>


                            </div>
                        </div>
                    </div>
                </div>
                <p className="text-muted p-4" style={{fontSize: '12px'}}>
                    * This Price does not Include Additional Taxes,
                    GST Will calculate on final Invoice page depends on country and
                    state if applicable
                </p>
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
